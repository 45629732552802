.footer-container {
  background-color: color(bg);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 59px 0;

  @media (max-width: 767px) {
    background-color: rgba(255, 255, 255, 0.05);
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    line-height: 18px;


    @media (max-width: 767px) {

      &__separator {
        display: none;
      }

      &__copyright {
        order: 4;
      }

      &__nav {
        flex-direction: column;
        padding: 10px 0;

        & a {
          opacity: 0.6;
        }
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 32px;
    }

    & > a:first-child {
      display: flex;
      align-items: center;
      gap: 16px;
      .logo-footer {
        width: 28px;
        height: 28px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    & > div {
      &:last-child {
        display: flex;
        align-items: center;
        gap: 24px;
      }
    }
  }
}
