
.wallet-modal {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    &-toast {
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.05);
      min-height: 56px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-weight: 500;
      color: #fff;
      padding: 10px 16px;
      font-size: 14px;
      margin-bottom: 14px;

      &__error {
        background-color: rgba(235, 69, 95, 0.08);
        color: rgba(235, 69, 95, 1);
      }
    }

    .wallet-container {
      display: grid;
      justify-items: center;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }
    .wallet-moda-item {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      justify-content: center;
      min-width: 120px;
      min-height: 150px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      border-radius: 20px;

      &-selected {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background-color: rgba(255, 255, 255, 0.05);
      }

      & > :first-child {
        width: 48px;
        height: 48px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .modal-btn {
      width: 100%;
      margin-top: 16px;
    }
  }