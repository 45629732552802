.card {
  padding: 16px;
  background: linear-gradient(
    107.92deg,
    rgba(255, 255, 255, 0) 2.94%,
    rgba(255, 255, 255, 0.08) 82.95%
  );
  border-radius: 16px;
  font-size: 16px;
  line-height: 20px;
  &_header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    padding-bottom: 16px;
    font-weight: 700;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      border-radius: 26px;
      background: linear-gradient(
        107.92deg,
        rgba(255, 255, 255, 0) 2.94%,
        rgba(255, 255, 255, 0.08) 82.95%
      );
      & > div {
        width: 24px;
        height: 24px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    & > :last-child {
      flex: 1;
    }
  }
  &_body {
    padding: 30px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: rgba(255, 255, 255, 0.4);

    .icon {
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
