.nav-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 88px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  margin: auto;
  z-index: 20;

  @media (max-width: 767px) {
    height: 64px;
  }
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .logo {
      width: 48px;
      height: 48px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .nav-btn {
      min-width: 154px;
      width: fit-content;
      padding: 0 24px;
      height: 48px;
      border-radius: 63px;
      color: #fff;
      background: rgba(255, 255, 255, 0.05);
      backdrop-filter: blur(7px);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      &:hover {
        backdrop-filter: grayscale(80%);
      }
      .walletIcon {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
