@font-face {
  font-family: PlusJakartaSans;
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/plusJakartaSans/PlusJakartaSans-ExtraLight.ttf');
}

@font-face {
  font-family: PlusJakartaSans;
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/plusJakartaSans/PlusJakartaSans-Light.ttf');
}

@font-face {
  font-family: PlusJakartaSans;
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/plusJakartaSans/PlusJakartaSans-Light.ttf');
}

@font-face {
  font-family: PlusJakartaSans;
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/plusJakartaSans/PlusJakartaSans-Medium.ttf');
}

@font-face {
  font-family: PlusJakartaSans;
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/plusJakartaSans/PlusJakartaSans-Regular.ttf');
}

@font-face {
  font-family: PlusJakartaSans;
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/plusJakartaSans/PlusJakartaSans-SemiBold.ttf');
}

@font-face {
  font-family: PlusJakartaSans;
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/plusJakartaSans/PlusJakartaSans-Bold.ttf');
}

@font-face {
  font-family: PlusJakartaSans;
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/plusJakartaSans/PlusJakartaSans-ExtraBold.ttf');
}
