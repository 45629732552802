.btn-primary {
  background: linear-gradient(
    119.45deg,
    #8239ff 8.65%,
    #e18dff 41.44%,
    #ff94d4 68.05%
  );
  box-shadow: inset 0px 0px 50px rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(7px);
  border-radius: 63px;
  color: #fff;
  padding: 14px;
  width: 100%;
  border: none;
  font-weight: 700;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
