.modal {
  border-radius: 24px;
  padding: 24px;
  opacity: 1;
  transition: all 0.2s ease;
  visibility: visible;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  overflow: hidden;
  background: rgba(17, 13, 39, 0.9);
  backdrop-filter: blur(7px);
  width: 100%;
  max-height: 100vh;
  z-index: 50;

  @media (max-width: 767px) {
    width: auto;
    min-width: 100%;
    max-width: 100%;
    margin: 16px;
  }


  ::-webkit-scrollbar {
    width: 3px !important;
  }

  ::-webkit-scrollbar-track {
    margin-top: 12px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 49;

  }

  &-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    z-index: 40;

  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    padding-bottom: 16px;

    .close-btn {
      width: 48px;
      height: 48px;
      padding: 12px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .modal-title {
    font-size: 20px;
    font-weight: 700;
  }
  .modal-body {
    flex-direction: column;
    max-height: 90vh;
    display: flex;  
    overflow-y: scroll;
  }
}
