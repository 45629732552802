@function color($color) {
  $result: map-get($colors, $color);
  @return $result;
}

@mixin respond-to($breakpoint) {
  $media: map-get($breakpoints, $breakpoint);

  @media #{$media} {
    @content;
  }
}
