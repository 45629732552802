/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden; 
  max-height: 100vh;
}

body {
  font-family: 'PlusJakartaSans', -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  background-color: color(bg);
  color: color(primary);
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
button {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
}

button,
span,
input,
p,
a,
h1,
h2,
h3,
h4,
h5 {
  font-family: inherit;
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 3px;
  @media (max-width: 992px) {
    width: 0;
  }
}
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 31px;
  max-height: 47px;
}
